import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { WIDTH } from '../../../constants/screenResolution';

export const NoticePill = styled.div`
  // padding: 2px 7px 4px 6px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  // padding-top: 3px;
  // padding-right: 9px;
  // border-radius: 20px;
  background: ${(props) => props.theme.palette.error.main};
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-family: Onest-Bold;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 18px;
    height: 18px;
  }
`;

export const SideNavigationWrapper = styled.div`
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #1d1d1d;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;

export const SideNavigationContainer = styled.div`
  background: #1d1d1d;
  heights: 100%;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
`;

export const NavigationItem = styled.div``;

export const NavigationTile = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background: ${(props) => (props.active ? '#2c2c2c' : 'transparent')};
  &:hover {
    background: #2c2c2c;
  }
`;

export const NavigationTileHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const NavigationTileTitle = styled.div`
  font: 0.875rem/1.125rem Onest-SemiBold;
  margin-left: 8px;
  color: #fff;
`;

export const NavigationTileAction = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SubOptionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  background: #2c2c2c;
`;

export const SubOption = styled.li<{ active?: boolean; disabled?: boolean }>`
  padding: 16px 16px 16px 60px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  font: 0.875rem/1.125rem
    ${(props) => (props.active ? 'Onest-SemiBold' : 'Onest-Medium')};
  color: ${(props) => (props.active ? '#fff' : '#808080')};
  background: ${(props) =>
    props.active ? '#404040' : props.disabled ? '#232323' : 'transparent'};
  display: flex;
  gap: 12px;
  align-items: center;
  &:hover {
    background: ${(props) => (props.disabled ? '#232323' : '#353535')};
  }
`;

export const ComingSoonTile = styled.div`
  font: 0.625rem/0.625 Onest-SemiBold;
  color: #fff;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  text-transform: uppercase;
  border-radius: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-align: center;
  padding: 0 6px;
`;

export const NewTileContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  justify-content: center;
  height: 20px;
  border-radius: 6px;
  padding: 0 8px;
  margin-left: 4px;
  font: 0.675rem/0.875rem Onest-SemiBold;
  background: ${(props) => props.theme.palette.primary.main};
  color: ${COLORS.white};
`;

export const BetaTileContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  justify-content: center;
  height: 20px;
  border-radius: 6px;
  padding: 0 8px;
  margin-left: 4px;
  font: 0.675rem/0.875rem Onest-SemiBold;
  border: 1px solid #ffffff80;
  color: #ffffff80;
`;

export const BetaTileContainerWithTheme = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  justify-content: center;
  height: 20px;
  border-radius: 6px;
  padding: 0 6px;
  margin-left: 4px;
  font: 0.675rem/0.875rem Onest-SemiBold;
  border: 1px solid ${(props) => props.theme.palette.text.t1}80;
  color: ${(props) => props.theme.palette.text.t1}80;
  cursor: pointer;
`;

export const ManagerStoreNavWrapper = styled.div``;

export const StoreImageWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 50%;
`;
